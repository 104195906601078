#login {
  background: url("../../../assets/content/bg2.jpeg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  padding-top: 2rem;

  svg {
    height: 150px;
  }

  .border-left {
    border-color: lighten(black, 70%) !important;
  }

  .oAuth {
    .btn {
      text-align: center;
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */

// Global variables
@import "../../../utilities/variables";

.modal {
  .modal-content {
    border-radius: 1rem !important;
  }

  .modal-header {
    background: $blue;
    color: white;

    .close {
      text-shadow: none;
      color: white;
    }
  }
}

@media screen and (max-height: 890px) {
  .modal {
    max-height: 90vh;
    height: auto;
    overflow-y: scroll;

    &.full-height {
      max-height: initial;
      height: 100% !important;
    }
  }
}

.insurancelist-modal {
  td {
    padding: 0.2rem !important;
  }
}

#phoneVerify {
  .animation-container {
    width: 150px;
    height: 150px;
    background: $blue;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .selection-container {
    .btn {
      padding-top: 2rem;
      padding-bottom: 2rem;

      i {
        color: grey;
      }
    }
  }

  .react-tel-input {
    .form-control {
      width: 100%;
    }
  }

  .styles_react-code-input-container__tpiKG {
    display: flex;
    justify-content: center;
    width: auto !important;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &.error {
      .styles_react-code-input__CRulA {
        input {
          border-color: #dc3545;
        }
      }
    }
  }
}

#signModal {
  .signature-pad-container {
    background: white;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;

    canvas {
      border: 2px solid grey;
      border-radius: 0.5rem;
      width: 100%;
      height: 200px;
    }

    .btn {
      display: block;
      width: 100%;
      margin-left: 0;
      margin-right: 0;

      &.btn-outline-red {
        border: 2px solid #ff3547 !important;
        background: transparentize($color: #ff3547, $amount: 0.99) !important;
        color: #ff3547 !important;

        &:hover {
          border: 2px solid #ff3547 !important;
          background-color: transparentize(
            $color: #ff3547,
            $amount: 0.95
          ) !important;
        }

        &.active {
          box-shadow: none;
          border: 2px solid #ff3547 !important;
          background-color: transparentize(
            $color: #ff3547,
            $amount: 0.95
          ) !important;
        }
      }
    }
  }
}

#signModal {
  margin-top: 10rem !important;
}

#qrModal,
#signModal {
  .modal-header {
    background: white !important;
    color: $dark !important;

    .close {
      color: black;
    }
  }

  .modal-body {
    background: $blue;
    color: white;

    .blue-bg {
      padding: 1rem;
      background: lighten($blue, 5%);
      border-radius: 0.5rem;

      .sign-steps {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;

        span {
          color: black;
          padding: 1rem;
          border-radius: 0.5rem;
          line-height: 1rem;
          background: white;
          margin-right: 1rem;
          position: relative;
        }

        &:nth-child(5),
        &:nth-child(4) {
          span {
            &::after {
              content: "";
              width: 2px;
              background: white;
              height: 20px;
              position: absolute;
              left: 50%;
              top: -20px;
            }
          }
        }
      }
    }

    .text-muted {
      color: darken(white, 15%) !important;
    }

    .qr {
      border-radius: 0.5rem;
    }
  }

  .modal-header {
    display: flex;
    align-items: center;

    .modal-title {
      width: auto;
      display: flex;
      align-items: center;
      text-align: left;

      .logo {
        width: auto;
        height: 40px;
        padding-right: 1rem;
      }

      .small {
        font-size: 0.8rem;
      }
    }
  }
}

#signatureModal {
  .btn {
    text-transform: initial;
  }

  .position-relative {
    overflow: hidden;
  }

  /*.signaturePlacement {
    height: 80px;
    width: 160px;
    border-radius: 0.25rem;
    background: transparentize($color: lightgray, $amount: 0.6);
    border: 2px $orange solid;
    position: absolute;
    z-index: 99;
    cursor: pointer;

    span {
      color: $orange;
      position: absolute;
      right: auto;
      left: auto;
      top: -25px;
      line-height: 25px;
      font-size: 10pt;
      width: 100%;
      text-align: center;
      background: rgba(255, 255, 255, 0.9);
      border-top: 2px grey solid;
      border-left: 2px grey solid;
      border-right: 2px grey solid;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }*/

  .signaturePlacement {
    position: absolute;
    z-index: 99;
    cursor: pointer;

    span {
      margin: auto;
      display: block;
      text-align: center;
      border-top: 3px grey solid;
      border-left: 2px grey solid;
      border-right: 2px grey solid;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      background: white;
      line-height: 25px;
      font-size: 10pt;
    }

    .area {
      height: 80px;
      width: 160px;
      background: transparentize($color: lightgray, $amount: 0.6);
      border: 2px $orange solid;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      position: relative;

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        line {
          stroke: rgba(0, 0, 0, 0.1);
          stroke-width: 2;
        }
      }
    }
  }

  .animation-container {
    height: 200px;
    width: 200px;
    margin: auto;
  }

  .scrollable {
    .react-pdf__Page {
      max-height: 60vh;
      overflow-y: scroll;
      margin-top: 0.5rem;
      border: 1px solid lightgrey;
      border-radius: 0.25rem;
      overflow-x: hidden;
      cursor: pointer;
    }
  }

  .react-pdf__Page {
    margin-top: 0.5rem;
    border: 1px solid lightgrey;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: transparentize($color: #555, $amount: 0.8);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: transparentize($color: #555, $amount: 0.7);
  }

  .react-pdf__Page__canvas {
    margin: auto;
    max-width: 100%;
    height: auto !important;
  }

  .react-pdf__Page__textContent {
    display: none;
  }
}

#assign-advisor {
  img {
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    border: 3px white solid;
    transition: all 0.2s ease;

    &.disabled {
      filter: saturate(0);
    }

    &.active {
      border: 3px $orange solid;
    }

    &:hover {
      border: 3px $blue solid;
    }
  }
}

#damagereportlist {
  .modal {
    height: 100%;
    max-height: unset;

    .modal-content {
      border-radius: 0 !important;
    }
  }

  .modal-body {
    padding: 0;

    .list-group {
      .list-group-item {
        cursor: pointer;
        text-align: left;

        &:hover {
          background: lighten(lightgray, 15%);
        }
      }
    }
  }

  .animation-container {
    width: 30%;
    margin: auto;
  }

  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    text-align: center;
    border-width: 4px;
    border-radius: 0.4rem;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    p {
      margin-bottom: 0;
    }

    &.active {
      border-color: #2196f3;
    }

    &.accept {
      border-color: #00e676;
    }

    &.reject {
      border-color: #ff1744;
    }
  }

  .messages {
    max-height: 450px;
    overflow-y: scroll;

    .msg {
      border-bottom: 1px lighten(lightgray, 10%) solid !important;
      margin-bottom: 1.5rem;

      .msg-img {
        width: 40px;
        min-width: 40px;
        margin-right: 1rem;

        img {
          width: 100%;
          border-radius: 50%;
        }
      }

      .mdb-lightbox {
        margin: 0.5rem 0 1rem 0;
        padding: 0 0;
      }
    }
  }

  .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .thumb {
      display: inline-flex;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      margin-bottom: 8;
      margin-right: 8;
      width: 100px;
      height: 100px;
      padding: 4px;
      box-sizing: border-box;

      .thumb-inner {
        display: flex;
        min-width: 0;
        overflow: hidden;

        .view {
          cursor: pointer;

          .mask {
            transition: all 0.2s ease;
          }

          &:hover {
            .mask {
              background: rgba(255, 0, 0, 0.2) !important;
            }
          }
        }

        img {
          display: block;
          width: auto;
          height: 100%;
        }
      }
    }
  }

  textarea {
    min-height: 100px;
    max-height: 300px;
  }
}

#damagereport {
  textarea {
    min-height: 100px;
    max-height: 300px;
  }

  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    text-align: center;
    border-width: 4px;
    border-radius: 0.4rem;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    p {
      margin-bottom: 0;
    }

    &.active {
      border-color: #2196f3;
    }

    &.accept {
      border-color: #00e676;
    }

    &.reject {
      border-color: #ff1744;
    }
  }

  .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;

    .thumb {
      display: inline-flex;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      margin-bottom: 8;
      margin-right: 8;
      width: 100px;
      height: 100px;
      padding: 4px;
      box-sizing: border-box;

      .thumb-inner {
        display: flex;
        min-width: 0;
        overflow: hidden;

        .view {
          cursor: pointer;

          .mask {
            transition: all 0.2s ease;
          }

          &:hover {
            .mask {
              background: rgba(255, 0, 0, 0.2) !important;
            }
          }
        }

        img {
          display: block;
          width: auto;
          height: 100%;
        }
      }
    }
  }
}

#identification {
  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    border-width: 4px;
    border-radius: 0.4rem;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    p {
      margin-bottom: 0;
    }

    &.active {
      border-color: #2196f3;
    }

    &.accept {
      border-color: #00e676;
    }

    &.reject {
      border-color: #ff1744;
    }
  }

  .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;

    .thumb {
      display: inline-flex;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      margin-bottom: 8;
      margin-right: 8;
      width: 100px;
      height: 100px;
      padding: 4px;
      box-sizing: border-box;

      .thumb-inner {
        display: flex;
        min-width: 0;
        overflow: hidden;

        .view {
          cursor: pointer;

          .mask {
            transition: all 0.2s ease;
          }

          &:hover {
            .mask {
              background: rgba(255, 0, 0, 0.2) !important;
            }
          }
        }

        img {
          display: block;
          width: auto;
          height: 100%;
        }
      }
    }
  }
}

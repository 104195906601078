#loginModal {
  .modal-content {
    overflow: hidden;

    .modal-body {
      padding: 0;

      .row {
        margin: 0;

        .md-form.md-outline .active {
          background: white;
        }

        .background-col-mobile {
          background-image: url("../../../../assets/content/bg2.jpeg");
          background-size: cover;
          padding: 1rem;
          height: 20vh;

          .logo {
            max-height: 80%;
            margin: auto;
            display: block;
          }
        }

        .background-col {
          background-image: url("../../../../assets/content/bg2.jpeg");
          background-size: cover;
          padding: 3rem;

          .logo {
            max-width: 80%;
            margin: auto;
            display: block;
          }
        }
      }
    }
  }
}

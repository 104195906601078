#signaturePage {
  .edge-header {
    height: 200px;
    margin-top: -3rem; // 2rem margin + 3rem padding of <main>
    padding-top: 1rem;

    .logo {
      max-height: 150px;
      max-width: 50%;
    }
  }

  canvas {
    border: 2px solid grey;
    background: white;
    border-radius: 0.5rem;
    width: 100%;
    height: 200px;
  }

  .btn {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */

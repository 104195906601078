@import "../../../utilities/variables";

.navbar {
  background: $blue !important;
  box-shadow: none;
  transition: all 0.2s ease;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
  }

  &.top-nav-collapse {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }

  .inbox {
    position: relative;
    overflow: visible;

    .badge {
      position: absolute;
      padding: 0.2rem 0.4rem;
      top: -6px;
      right: -6px;
    }
  }

  .nav-btn {
    padding: 0.6rem 1rem;
    font-size: 1rem;
  }

  svg {
    height: 4rem;
  }
}

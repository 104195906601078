html,
body {
  background: #f5f5f5;
}

#root {
  height: 100%;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  flex: 1;
}

// Custom text
.pink-text {
  color: $pink !important;
}

.blue-text {
  color: $blue !important;
  transition: all 0.2s ease;

  &.clickable {
    &:hover {
      color: lighten($blue, 10%) !important;
    }
  }

  a {
    color: $blue !important;

    &:hover {
      color: lighten($blue, 10%) !important;
    }
  }
}

.blue {
  background: $blue !important;
}

// Buttons
.btn {
  box-shadow: none !important;
  border-radius: 0;
  text-transform: initial;
  border-radius: 1rem;

  i {
    margin-right: 0.3rem;
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }

  &.btn-grey {
    background: darken(white, 3%) !important;
    color: #212529;

    &:hover {
      background: darken(white, 5%) !important;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-color: darken(white, 10%) !important;
    }
  }

  &.btn-darkblue {
    background: darken($blue, 4%) !important;
    color: white;

    &:hover {
      background: darken($blue, 6%) !important;
    }
  }

  &.btn-blue {
    background: $blue !important;

    &:hover {
      background: lighten($blue, 10%) !important;
    }
  }

  &.btn-orange {
    background: #f57c00 !important;

    &:hover {
      background: lighten(#f57c00, 10%) !important;
    }
  }

  &.btn-warning {
    background: #fb3 !important;

    &:hover {
      background: lighten(#fb3, 7%) !important;
    }
  }
}

.alert {
  border-radius: 1rem;
}

// Cards
.card {
  box-shadow: none;
  border-radius: 1rem;
  background: transparentize($color: white, $amount: 0.25) !important;

  &.testimonial-card {
    overflow: hidden;
  }

  &.no-blue {
    .card-header {
      display: none;
    }
  }

  .card-header {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    background: $blue;
    color: white;
  }

  .view {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem;
  }
}

// Generic
.clickable {
  cursor: pointer !important;
}

.global-preloader {
  position: absolute;
  z-index: 1031;
  top: 0;
  width: 100%;
  background-color: lighten($orange, 30%);

  .indeterminate {
    background-color: $orange !important;
  }
}

.flex-center ul li {
  margin-bottom: 0 !important;
}

.not-rounded {
  border-radius: 0 !important;
}

.splitter {
  overflow: hidden;
}

.or {
  position: relative !important;
  padding-bottom: var(--spacing-rule-text-vertical, 16px) !important;
  padding-top: var(--spacing-rule-text-vertical, 16px) !important;
  padding-left: var(--spacing-rule-text-horizontal, 16px) !important;
  padding-right: var(--spacing-rule-text-horizontal, 16px) !important;
}

.or-text {
  overflow-wrap: break-word !important;
  margin: 0 !important;

  &::before {
    border-bottom-width: var(--border-rule-border-width, 1px) !important;
    border-bottom-color: #e4e4e4 !important;
    content: "" !important;
    position: absolute !important;
    border-bottom-style: solid !important;
    top: 50% !important;
    right: 100% !important;
    width: 5000px !important;
  }

  &::after {
    border-bottom-width: var(--border-rule-border-width, 1px) !important;
    border-bottom-color: #e4e4e4 !important;
    content: "" !important;
    position: absolute !important;
    border-bottom-style: solid !important;
    top: 50% !important;
    left: 100% !important;
    width: 5000px !important;
  }
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: lighten($orange, 30%);

  &:after {
    background-color: $orange;
  }
}

.mt-10 {
  margin-top: 10rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.my-10 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */

@import "../../../utilities/variables";
@import "../../../utilities/breakpoints";

@include media("<=phone") {
  .modal {
    min-height: 100vh;
    max-height: 100vh;

    .modal-dialog {
      margin: 0;
      height: 100%;

      .modal-content {
        min-height: 100vh;
        border-radius: 0 !important;
      }
    }
  }
}

#home {
  &.showbg {
    background: url("../../../assets/content/bg3.jpeg");
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    height: 100%;
  }

  .internal {
    button {
      padding: 0;
    }

    .view {
      cursor: pointer;

      &::after {
        content: "Direkt online berechnen und abschließen";
        position: absolute;
        background: transparentize($color: $blue, $amount: 0.5);
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1rem;
        color: white;
      }
    }
  }

  .landing-page {
    input {
      font-size: 2rem;
      font-weight: bold;
      color: $blue;
      margin-left: 0.5rem;
    }

    .md-form {
      width: auto;
    }

    .md-form input:not([type]):focus:not([readonly]),
    .md-form input[type="text"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="password"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="email"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="url"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="time"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="date"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="datetime"]:not(.browser-default):focus:not([readonly]),
    .md-form
      input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="tel"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="number"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="search"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="phone"]:not(.browser-default):focus:not([readonly]),
    .md-form input[type="search-md"]:focus:not([readonly]),
    .md-form textarea.md-textarea:focus:not([readonly]) {
      border-bottom: 1px solid $orange;
      box-shadow: 0 1px 0 0 $orange;
    }
  }

  .md-form.md-outline label.active {
    background: #f5f5f5;
  }

  .offers {
    button {
      background: none;
      border: none;
      width: 100%;
      height: auto;
      display: inline-block;
      height: 100%;
    }

    .card {
      height: 100%;
      transition: all 0.2s ease;
      //border: 3px white solid;
      cursor: pointer;
      box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.05),
        0 6px 20px 0 rgba(0, 0, 0, 0.04) !important;

      i {
        transition: all 0.2s ease;
        color: grey;
      }

      .view {
        max-height: 175px;

        img {
          transition: all 0.2s ease;
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
        }
      }

      &:hover {
        //border: 3px $blue solid;
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.15),
          0 6px 20px 0 rgba(0, 0, 0, 0.15) !important;
        transform: scale(1.02);
        border-radius: 1rem;

        i {
          color: $blue;
        }

        .view {
          overflow: hidden;

          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }

  .open-progress {
    .progress {
      border-radius: 0px;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
  }
}

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */

#qrPage {
  .scanner {
    max-width: 100%;
    width: 50%;
  }

  @media screen and (max-width: 992px) {
    .scanner {
      max-width: 100%;
      width: 100%;
    }
  }
}

@import "../../../utilities/variables";

.household-insurance {
  .btn-white {
    background: transparentize($color: white, $amount: 0.5) !important;

    &:hover {
      background: transparentize($color: white, $amount: 0) !important;
    }
  }

  .success {
    .col-lg-5 {
      margin-bottom: 2rem;
    }

    .card-body {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      img {
        max-width: 100%;
        height: 70px;
        margin-bottom: 1rem;
      }

      .results {
        div {
          width: 100%;
        }
      }
    }
  }
}

.module-singlechoicecard {
  &.states {
    img {
      border: 2px solid lightgrey;
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .disabled {
    filter: saturate(0);
  }

  .card {
    cursor: pointer;
    transition: all 0.2s ease;
    border: 4px white solid;
    margin-bottom: 2rem;

    .card-body {
      img {
        max-width: 50%;
        max-height: 75px;
        display: block;
        margin: auto;
        margin-bottom: 0.5rem;
        outline: 1px solid lightgrey;
        border-radius: 1rem;
      }

      svg {
        max-width: 50%;
        max-height: 100px;
        display: block;
        margin: auto;
        margin-bottom: 1rem;
      }
    }

    &.active {
      border: 4px $blue solid;
    }

    &.inactive {
      filter: saturate(0.3);

      &:hover {
        filter: saturate(0.7);
      }
    }

    &:hover {
      background: darken(white, 2%);
    }
  }
}

.optionalParts {
  .list-group-item {
    padding: 0;

    &.selected-insurance {
      .price {
        background: $blue;
        color: white;
        padding: 1rem 2rem !important;
      }
    }

    .switch-container {
      background: darken(white, 2%);
      display: flex;
      align-items: center;
      padding: 1.5rem;

      .switch {
        label {
          margin-bottom: 0;
        }
      }
    }

    .optional-info {
      padding: 1.5rem;
    }
  }
}

.animated {
  opacity: 0;
}

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */

$dark: #2f2f2f;
$light: #fff;
$pink: #f27983;
$orange: #e87100;
$blue: #006eb7;
$green: #3ab56f;
$lightgreen: #80f2a8;

/** 
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2021 InspireMedia GmbH
 */

@import "../../../utilities/variables";
@import "../../../utilities/breakpoints";

#profile {
  background-image: url("../../../assets/content/bg.jpeg");
  background-size: cover;
  min-height: 100vh;
  padding: 2rem 0;

  #employee {
    .list-group {
      .list-group-item {
        padding: 1rem !important;
        text-align: left;
        transition: all 0.2s ease;
        padding: 0;
        cursor: pointer;
        margin-bottom: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.125);
        transition: all 0.2s ease;
        overflow: hidden;

        &:hover {
          background: darken(white, 3%);
        }
      }
    }

    .chat {
      .modal-body {
        padding: 0;

        .list-group {
          .list-group-item {
            cursor: pointer;
            text-align: left;

            &:hover {
              background: lighten(lightgray, 15%);
            }
          }
        }
      }

      .wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        text-align: center;
        border-width: 4px;
        border-radius: 0.4rem;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border 0.24s ease-in-out;

        p {
          margin-bottom: 0;
        }

        &.active {
          border-color: #2196f3;
        }

        &.accept {
          border-color: #00e676;
        }

        &.reject {
          border-color: #ff1744;
        }
      }

      .messages {
        max-height: 450px;
        overflow-y: scroll;

        .msg {
          border-bottom: 1px lighten(lightgray, 10%) solid !important;
          margin-bottom: 1.5rem;

          .msg-img {
            width: 40px;
            min-width: 40px;
            margin-right: 1rem;

            img {
              width: 100%;
              border-radius: 50%;
            }
          }

          .mdb-lightbox {
            margin: 0.5rem 0 1rem 0;
            padding: 0 0;
          }
        }
      }

      .thumbs-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 1rem;

        .thumb {
          display: inline-flex;
          border-radius: 4px;
          border: 1px solid #eaeaea;
          margin-bottom: 8;
          margin-right: 8;
          width: 100px;
          height: 100px;
          padding: 4px;
          box-sizing: border-box;

          .thumb-inner {
            display: flex;
            min-width: 0;
            overflow: hidden;

            .view {
              cursor: pointer;

              .mask {
                transition: all 0.2s ease;
              }

              &:hover {
                .mask {
                  background: rgba(255, 0, 0, 0.2) !important;
                }
              }
            }

            img {
              display: block;
              width: auto;
              height: 100%;
            }
          }
        }
      }

      textarea {
        min-height: 100px;
        max-height: 300px;
      }
    }

    .table {
      td {
        vertical-align: middle;
      }

      img {
        border-radius: 50%;
        height: 40px;
        cursor: pointer;
      }

      .inbox {
        position: relative;
        overflow: visible;
        padding: 0.7rem 0.9rem;

        .badge {
          position: absolute;
          padding: 0.2rem 0.4rem;
          top: -6px;
          right: -6px;
        }
      }
    }

    #idcardcheck {
      img {
        max-height: 250px;
      }
    }
  }

  #overview {
    .modal-title {
      width: 100%;

      .md-form {
        width: 100%;
      }
    }

    .sign-container {
      background: darken(white, 4%);
      padding: 1rem;
      border-radius: 0.2rem;
    }

    .list-group {
      .list-group-item {
        padding: 1rem !important;
        text-align: left;
        transition: all 0.2s ease;

        .fa-signature {
          background: darken(white, 4%);
          border: 1px lightgrey solid;
          height: 30px;
          width: 30px;
          display: flex;
          border-radius: 2rem;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          background: darken(white, 3%);
        }
      }
    }
  }

  #customer {
    .insurance-list {
      .list-group-item {
        padding: 0;
        cursor: pointer;
        margin-bottom: 1rem;
        border: 1px solid rgba(0, 0, 0, 0.125);
        transition: all 0.2s ease;
        overflow: hidden;

        .color-badge {
          width: 8px;
          display: flex;
          align-items: stretch;
        }

        .status-container {
          background: darken(white, 3%);

          .status {
            padding: 0.5rem;
            width: 100%;
            justify-content: space-between;

            .status-arrow {
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .status-item {
              display: flex;
              width: 27.5%;
              padding: 0.6rem 0;
              border-radius: 1rem;
              background: darken(white, 10%);
              justify-content: center;
              align-items: center;
              cursor: pointer;

              &.active {
                background: $blue;
                color: white;
              }

              &.finished {
                position: relative;

                span {
                  &:after {
                    position: absolute;
                    margin-left: 5px;
                    color: #01ad79;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f00c";
                  }
                }
              }
            }
          }

          @include media("<=phone") {
            .status-arrow {
              display: none !important;
            }

            .status-item {
              width: 45% !important;
              padding: 0.5rem !important;

              &:first-child {
                display: none;
              }
            }
          }
        }

        &.disabled {
          filter: saturate(0);
        }

        .img-wrapper {
          width: 150px;
          height: 100%;
          height: auto;
          overflow: hidden;
          background-image: url("../../../assets/content/insurances/haushaltsversicherung/lead.jpg");
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          transition: all 0.2s ease;
        }

        .insurance-container {
          transition: all 0.2s ease;
          flex: 1;
          padding-right: 1rem;

          &:hover {
            background: darken(white, 3%);
          }

          .animation-container {
            height: 150px;
            width: 200px;
          }

          .insurance-info {
            flex: 1;
          }

          .insurance-partner {
            max-width: 100px;
            max-height: 50px;
          }

          @include media("<=phone") {
            display: block !important;

            .animation-container {
              display: none;
            }
          }
        }
      }
    }
  }

  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
    border-width: 4px;
    border-radius: 0.4rem;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    p {
      margin-bottom: 0;
    }

    &.active {
      border-color: #2196f3;
    }

    &.accept {
      border-color: #00e676;
    }

    &.reject {
      border-color: #ff1744;
    }
  }

  .thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;

    .thumb {
      display: inline-flex;
      border-radius: 4px;
      border: 1px solid #eaeaea;
      margin-bottom: 8;
      margin-right: 8;
      width: 100px;
      height: 100px;
      padding: 4px;
      box-sizing: border-box;

      .thumb-inner {
        display: flex;
        min-width: 0;
        overflow: hidden;

        .view {
          cursor: pointer;

          .mask {
            transition: all 0.2s ease;
          }

          &:hover {
            .mask {
              background: rgba(255, 0, 0, 0.2) !important;
            }
          }
        }

        img {
          display: block;
          width: auto;
          height: 100%;
        }
      }
    }
  }
}

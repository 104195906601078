@import "../../../utilities/variables";

.page-footer {
  color: #212121;
  background: #f5f5f5 !important;
  z-index: 5;

  a {
    color: #6c757d !important;

    &.blue-text {
      color: $blue !important;

      &:hover {
        color: lighten($blue, 10%) !important;
      }
    }

    &:hover {
      color: $blue !important;
    }
  }

  .footer-copyright {
    background: #f5f5f5 !important;
  }
}
